/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { onShowSizeChange, itemRender } from '../Pagination';
import { CSVReader } from "react-papaparse";
import Breadcrumb from "../Breadcrumb";
import { useGetHospitals } from "../service/onboarding.service";
import { useGetBranchesByHospital } from "../service/branch.service";
import { notification, Table } from "antd";
import { useSelectedUser } from "../staff/SelectedUserProvider";
import { useAddBulkPatient } from "../service/patient.service";
import moment from "moment/moment";

function DataUpload() {

    const { loginUser } = useSelectedUser();

    const [uploadPreview, setUploadedPreview] = useState([]);
    const [uploadedPayload, setUploadedPayload] = useState([]);
    const [hospitals, setHospitals] = useState([]);
    const [branches, setBranches] = useState([]);
    const [hospitalId, setHospitalId] = useState(null);
    const [filter, setFilter] = useState({
        hospitalId: 0, branchId: 0, uploadType: ''
    });

    const { data: hospitalsData, isFetched, error } = useGetHospitals();
    const { data: branchesData, isFetched: isBranchFetched } = useGetBranchesByHospital(hospitalId);

    const saveBulkPatient = useAddBulkPatient();

    //useAddBulkPatient

    const uploadThpeOptions = [
        'Patient Bio Data',
        'Patient Visits',
        'Patient Vitals',
        'Patient Diagnosis',
        'Patient Medications',
    ];

    const columns = [
        { title: 'alcohol', dataIndex: 'alcohol', key: 'alcohol' },
        { title: 'alt_phone_number', dataIndex: 'alt_phone_number', key: 'alt_phone_number' },
        { title: 'breastfeeding', dataIndex: 'breastfeeding', key: 'breastfeeding' },
        { title: 'card_number', dataIndex: 'card_number', key: 'card_number' },
        { title: 'city', dataIndex: 'city', key: 'city' },
        { title: 'country_code', dataIndex: 'country_code', key: 'country_code' },

        { title: 'created_at', dataIndex: 'created_at', key: 'created_at' },
        { title: 'date_deceased', dataIndex: 'date_deceased', key: 'date_deceased' },
        { title: 'dob', dataIndex: 'dob', key: 'dob' },
        { title: 'email_address', dataIndex: 'email_address', key: 'email_address' },
        { title: 'ethnicity_id', dataIndex: 'ethnicity_id', key: 'ethnicity_id' },
        { title: 'first_name', dataIndex: 'first_name', key: 'first_name' },
        { title: 'gender', dataIndex: 'gender', key: 'gender' },
        { title: 'guardian_city', dataIndex: 'guardian_city', key: 'guardian_city' },
        { title: 'guardian_email', dataIndex: 'guardian_email', key: 'guardian_email' },
        { title: 'guardian_first_name', dataIndex: 'guardian_first_name', key: 'guardian_first_name' },

        { title: 'guardian_last_name', dataIndex: 'guardian_last_name', key: 'guardian_last_name' },
        { title: 'guardian_phone_number', dataIndex: 'guardian_phone_number', key: 'guardian_phone_number' },
        { title: 'guardian_region', dataIndex: 'guardian_region', key: 'guardian_region' },
        { title: 'guardian_residential_address', dataIndex: 'guardian_residential_address', key: 'guardian_residential_address' },
        { title: 'heard_about_us', dataIndex: 'heard_about_us', key: 'heard_about_us' },
        { title: 'language', dataIndex: 'language', key: 'language' },
        { title: 'last_name', dataIndex: 'last_name', key: 'last_name' },
        { title: 'marital_status', dataIndex: 'marital_status', key: 'marital_status' },
        { title: 'middle_name', dataIndex: 'middle_name', key: 'middle_name' },
        { title: 'national_id', dataIndex: 'national_id', key: 'national_id' },

        { title: 'nationality', dataIndex: 'nationality', key: 'nationality' },
        { title: 'nav_customer_number', dataIndex: 'nav_customer_number', key: 'nav_customer_number' },
        { title: 'occupation', dataIndex: 'occupation', key: 'occupation' },
        { title: 'phone_number', dataIndex: 'phone_number', key: 'phone_number' },
        { title: 'postal_code', dataIndex: 'postal_code', key: 'postal_code' },
        { title: 'pregnancy', dataIndex: 'pregnancy', key: 'pregnancy' },
        { title: 'reason_deceased', dataIndex: 'reason_deceased', key: 'reason_deceased' },
        { title: 'relationship', dataIndex: 'relationship', key: 'relationship' },
        { title: 'religion', dataIndex: 'religion', key: 'religion' },
        { title: 'smoking', dataIndex: 'smoking', key: 'smoking' },

        { title: 'ss_number', dataIndex: 'ss_number', key: 'ss_number' },
        { title: 'state', dataIndex: 'state', key: 'state' },
        { title: 'status', dataIndex: 'status', key: 'status' },
        { title: 'street', dataIndex: 'street', key: 'street' },
        { title: 'surgery', dataIndex: 'surgery', key: 'surgery' },
        { title: 'title', dataIndex: 'title', key: 'title' },
    ];

    useEffect(() => {
        // fetch hospitals
        if (isFetched && hospitalsData?.data && Array.isArray(hospitalsData.data)) {

            setHospitals(hospitalsData.data);

            setHospitalId(null);
        }

        if (isBranchFetched && branchesData?.data) {
            setBranches(branchesData?.data);
        }

        // fetch branches
    }, [isFetched, isBranchFetched, hospitalsData?.data, branchesData?.data]);

    const handleChange = (e) => {

        const { name, value } = e.target;

        if (name === 'hospitalId') {
            setHospitalId(value);
            setBranches([]);
        }

        setFilter({ ...filter, [name]: value });

    }

    const handleOnDrop = (data, file) => {

        let uploadedPayload = [], uploadPreview = [];

        

        data.map((dt, idx) => {

            if (idx === 0) {
                return;
            }
   
            console.log("row: ",dt.data)
            
            uploadPreview.push({
                'key': idx,
                'alcohol': dt.data[0],
                'alt_phone_number': dt.data[1],
                'breastfeeding': dt.data[2],
                'card_number': dt.data[3],
                'city': dt.data[4],
                'country_code': dt.data[5],
                'created_at': dt.data[6],
                'date_deceased': dt.data[7],
                'dob': dt.data[8],
                'email_address': dt.data[9],
                'ethnicity_id': dt.data[10],
                'first_name': dt.data[11],
                'gender': dt.data[12],
                'guardian_city': dt.data[13],
                'guardian_email': dt.data[14],
                'guardian_first_name': dt.data[15],
                'guardian_last_name': dt.data[16],
                'guardian_phone_number': dt.data[17],
                'guardian_region': dt.data[18],
                'guardian_residential_address': dt.data[19],
                'heard_about_us': dt.data[20],
                'language': dt.data[21],
                'last_name': dt.data[22],
                'marital_status': dt.data[23],
                'middle_name': dt.data[24],
                'national_id': dt.data[25],
                'nationality': dt.data[26],
                'nav_customer_number': dt.data[27],
                'occupation': dt.data[28],
                'phone_number': dt.data[29],
                'postal_code': dt.data[30],
                'pregnancy': dt.data[31],
                'reason_deceased': dt.data[32],
                'relationship': dt.data[33],
                'religion': dt.data[34],
                'smoking': dt.data[35],
                'ss_number': dt.data[36],
                'state': dt.data[37],
                'status': dt.data[38],
                'street': dt.data[39],
                'surgery': dt.data[40],
                'title': dt.data[41]
            });

            if (dt.cardNumber !== '' && dt.cardNumber !== 'null') {
                uploadedPayload.push({
                    'hospitalId': filter.hospitalId,
                    'branchId': filter.branchId,
                    'country': 'Ghana',
                    'createdBy': loginUser.username,
                    'alcohol': dt.data[0],
                    'altPhoneNumber': dt.data[1],
                    'breastfeeding': dt.data[2],
                    'cardNumber': dt.data[3],
                    'city': dt.data[4],
                    'countryCode': dt.data[5],
                    'createdAt': moment(dt.data[6]),
                    'dateDeceased': moment(dt.data[7]),
                    'dob': moment(dt.data[8]),
                    'emailAddress': dt.data[9],
                    'ethnicityId': dt.data[10],
                    'firstName': dt.data[11],
                    'gender': ((dt?.data?.[12] || '').toLowerCase() === 'female') ? dt.data[12] : 'Male',
                    'guardianCity': dt.data[13],
                    'guardianEmail': dt.data[14],
                    'guardianFirstName': dt.data[15],
                    'guardianLastName': dt.data[16],
                    'guardianPhoneNumber': dt.data[17],
                    'guardianRegion': dt.data[18],
                    'guardianResidentialAddress': dt.data[19],
                    'heardAboutUs': dt.data[20],
                    'language': dt.data[21],
                    'lastName': dt.data[22],
                    'maritalStatus': dt.data[23],
                    'middleName': dt.data[24],
                    'nationalId': dt.data[25],
                    'nationality': dt.data[26],
                    'navCustomerNumber': dt.data[27],
                    'occupation': dt.data[28],
                    'phoneNumber': dt.data[29],
                    'postalCode': dt.data[30],
                    'pregnancy': dt.data[31],
                    'reasonDeceased': dt.data[32],
                    'relationship': dt.data[33],
                    'religion': dt.data[34],
                    'smoking': dt.data[35],
                    'ssNumber': dt.data[36],
                    'state': dt.data[37],
                    'status': dt.data[38],
                    'street': dt.data[39],
                    'surgery': dt.data[40],
                    'title': dt.data[41]
                });
            }
            
            return;
        });

        setUploadedPreview(uploadPreview);

        setUploadedPayload(uploadedPayload);
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }

    const handleInfo = (data) => {
        console.log('Data:', data);
    }

    const handleOnRemoveFile = (data) => {
        setUploadedPreview([]);
        // console.log('File removed:', data);
    }

    const commitUpload = () => {
        notification.success({
            message: 'Data Upload',
            description: 'Data uploaded started...'
        });

        saveBulkPatient.mutate(uploadedPayload.slice(0, 500));

        if (saveBulkPatient.isError) {
            saveBulkPatient.isError && notification.error({
                message: 'Data Upload',
                description: 'Data upload failed...' + saveBulkPatient.error.message
            });
        } else {
            setUploadedPreview([]);
        }
    }

    return (
        <div className="content">
            <Breadcrumb title1={'Upload Data'} title2={'Upload Data'} menuItem={10} />
            <div className="row">
                <div className="col-sm-12">
                    <div id="dataUploadCon" className="card">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-heading"> <h4>Upload Data</h4> </div>
                                </div>

                                <div className="col-12 d-flex justify-content-between">

                                    <div className="form-group local-forms me-2">
                                        <label> Hospital <span className="login-danger">*</span> </label>
                                        <select className="form-control" name='hospitalId' style={{ minWidth: '300px' }}
                                            onChange={handleChange} defaultValue={filter.hospitalId} >
                                            <option value={''} key={0}>Select hospital</option>
                                            {hospitals.map((dtype, idx) =>
                                                <option value={dtype.hospitalId} key={idx + 1}>{dtype.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group local-forms me-2">
                                        <label> Branch <span className="login-danger">*</span> </label>
                                        <select className="form-control" name='branchId' style={{ minWidth: '300px' }}
                                            onChange={handleChange} defaultValue={filter.branchId} >
                                            <option value={''} key={0}>Select branch</option>
                                            {branches.map((branch, idx) =>
                                                <option value={branch.branchId} key={idx + 1}>{branch.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group local-forms me-2">
                                        <label> Upload Type <span className="login-danger">*</span> </label>
                                        <select className="form-control" name='uploadType' style={{ minWidth: '300px' }}
                                            onChange={handleChange} defaultValue={filter.uploadType} >
                                            <option value={''} key={0}>Select type</option>
                                            {uploadThpeOptions.map((dtype, idx) =>
                                                <option value={dtype} key={idx + 1}>{dtype}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                    <div className="responsive-table" style={{ overflow: 'scroll' }}>
                                        <div className="d-flex justify-content-between">
                                            <div className="table-responsive flex-grow-3">
                                                <Table pagination={{
                                                    total: uploadPreview.length,
                                                    showTotal: (total, range) =>
                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    onShowSizeChange: onShowSizeChange,
                                                    itemRender: itemRender
                                                }}
                                                    dataSource={uploadPreview} columns={columns} />
                                            </div>
                                            <div className="flex-grow-1" style={{ maxWidth: '150px' }}>
                                                {uploadPreview.length > 0 &&
                                                    <div className="me-2 mt-2">
                                                        <button type="submit" className="btn btn-primary submit-form" onClick={commitUpload}>
                                                            <i className="fas fa-save" /> Commit
                                                        </button>
                                                    </div>}
                                                {filter.uploadType !== '' &&
                                                    <div className="text-center align-self-end" style={{ minWidth: '150px' }}>
                                                        <b>Click / Drop CSV</b>
                                                        <div className="p-2">
                                                            <CSVReader
                                                                onDrop={handleOnDrop}
                                                                onError={handleOnError}
                                                                accept={'.csv'}
                                                                addRemoveButton
                                                                onFileLoaded={handleInfo}
                                                                onRemoveFile={handleOnRemoveFile}>
                                                                <span>Drop CSV file here or click to upload.</span>
                                                            </CSVReader>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataUpload;
