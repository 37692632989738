/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import {
    blog, dashboard, doctor, doctorschedule, logout, menuicon04,
    menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, menuicon12, menuicon14,
    menuicon15, menuicon16, patients, dep_icon1, sidemenu
} from './imagepath';
import Scrollbars from "react-custom-scrollbars-2";
import { useGetRoleAccessControlLists } from './service/accesscontrol.service';
import _ from 'lodash';
import { useSelectedUser } from './staff/SelectedUserProvider';


const Sidebar = (props) => {

    const { menuList, loginRole, loginFeatures: features } = useSelectedUser();

    // const { data: features, isSuccess, isError } = useGetRoleAccessControlLists(loginUser?.roleId || 0);
    
    const [selected, setSelected] = useState({});
    const [isData, setData] = useState(false);

    const handleClick = (e, item, item1, item3) => {
        const alldiv = document.querySelectorAll(`ul[class^='menu-items']`),
            div = document.querySelector(`#${item}`),
            ulDiv = document.querySelector(`.${item1}`);

        alldiv.forEach(ul => {
            ul.style.display = 'none';
        });

        if (alldiv.length > 0 && alldiv.style) {
            alldiv.style.display = 'none';
        }

        if (ulDiv) {
            ulDiv.style.display = (ulDiv.style.display === 'none') ? 'block' : 'none';
        }

        if (ulDiv?.style.display === 'none') {
            // div.classList.add('subdrop');
        } else {
            // div.classList.remove('subdrop');
        }
    };

    useEffect(() => {
        if (props?.id && props?.id1) {
            const ele = document.getElementById(`${props?.id}`);
            
            handleClick(ele, props?.id, props?.id1);
        }

        if (features) {
            let assigned = {}, skeys = [], fdata = [];

            Object.keys(features).map((key) => {

                fdata = Array.isArray(features[`${key}`]) ? features[`${key}`] : [];

                assigned[`${key}`] = fdata.map((item) => item.menuId);

                skeys = [...skeys, ...fdata];
            });

            setSelected(assigned);

            setData(true);
        }

    }, [props?.id, props?.id1, features]);

    const getImg = (icon) => {
        let icons = {
            'blog': blog,
            'dashboard': dashboard,
            'doctor': doctor,
            'doctorschedule': doctorschedule,
            'logout': logout,
            'menuicon04': menuicon04,
            'menuicon06': menuicon06,
            'menuicon08': menuicon08,
            'menuicon09': menuicon09,
            'menuicon10': menuicon10,
            'menuicon11': menuicon11,
            'menuicon12': menuicon12,
            'menuicon14': menuicon14,
            'menuicon15': menuicon15,
            'menuicon16': menuicon16,
            'patients': patients,
            'dep_icon1': dep_icon1,
            'sidemenu': sidemenu
        }

        return icons[icon];
    }

    const expandMenu = () => {
        document.body.classList.remove("expand-menu");
    };
    const expandMenuOpen = () => {
        document.body.classList.add("expand-menu");
    };

    return (
        <div className="sidebar" id="sidebar">
            <Scrollbars autoHide autoHideTimeout={1000}
                autoHideDuration={200} autoHeight
                autoHeightMin={0} autoHeightMax="87vh"
                thumbMinSize={30} universal={false}
                hideTracksWhenNotNeeded={true} >
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu" onMouseLeave={expandMenu} onMouseOver={expandMenuOpen} >
                        <ul>
                            {isData && Object.keys(menuList.mainMenus).map((key, index) => (menuList.menus[`${key}`] && selected[`${key}`] !== undefined) && (![1, 2].includes(parseInt(key)) ?
                                <li key={index} className="submenu">
                                    <Link to={`${menuList.mainMenus[key][0].link}`} id={`menu-item${key}`}
                                        onClick={(e) => handleClick(e, `menu-item${key}`, `menu-items${key}`)}>
                                        <span className="menu-side">
                                            {(`${menuList.mainMenus[key][0].icon}`.includes('fa-')) ? <i className={`fa ${menuList.mainMenus[key][0].icon}`} /> : <img src={getImg(`${menuList.mainMenus[key][0].icon}`)} alt="" />}
                                        </span>{" "}
                                        <span style={{ textTransform: 'capitalize' }}>{`${menuList.mainMenus[key][0].name.toLowerCase()}`.replaceAll('_', '/')} </span> <span className="menu-arrow" />
                                    </Link>
                                    <ul style={{ display: "none" }} className={`menu-items${key}`}>
                                        {menuList.menus[`${key}`].map((m, i) => selected[`${key}`].includes(m.orderId) && <li key={m.orderId}>
                                            <Link className={props?.activeClassName === m.link ? 'active' : ''} to={`/${m.link}`}>{m.name}</Link>
                                        </li>)}
                                    </ul>
                                </li> : <li key={key}>
                                    <Link id="menu-item" className={props?.activeClassName === `${menuList.mainMenus[key][0].link}` ? 'active' : ''} to={`/${menuList.mainMenus[key][0].link}`}
                                        onClick={(e) => { handleClick(e, "menu-item", "menu-item") }}>
                                        <span className="menu-side">
                                            {(`${(menuList.mainMenus[key][0].icon)}`.includes('fa-')) ? <i className={`fa ${menuList.mainMenus[key][0].icon}`} /> : <img src={getImg(`${menuList.mainMenus[key][0].icon}`)} alt="" />}
                                        </span>
                                        <span style={{ textTransform: 'capitalize' }}>{`${menuList.mainMenus[key][0].name.toLowerCase()}`.replaceAll('_', '/')}</span>
                                    </Link>
                                </li>))}

                            {loginRole.roleType === 'SUPER_ADMIN' && <li className="submenu">
                                <Link to="#" id="menu-item10" onClick={(e) => handleClick(e, "menu-item10", "menu-items10")}>
                                    <span className="menu-side">
                                        <img src={menuicon08} alt="" />
                                    </span>
                                    <span> On Boarding  </span> <span className="menu-arrow" />
                                </Link>
                                <ul style={{ display: "none" }} className="menu-items10">
                                    <li>
                                        <Link className={props?.activeClassName === 'onboarding' ? 'active' : ''} to="/onboarding">Hospital List</Link>
                                    </li>
                                    <li>
                                        <Link className={props?.activeClassName === 'bcsetup' ? 'active' : ''} to="/bcsetup">BC Setup</Link>
                                    </li>
                                    <li>
                                        <Link className={props?.activeClassName === 'apisetup' ? 'active' : ''} to="/apisetup">API Setup</Link>
                                    </li>
                                    <li>
                                    <Link className={props?.activeClassName === 'insurance-list' ? 'active' : ''} to="/insurancelist">Insurance Company List</Link>
                                    </li>
                                    <li>
                                        <Link className={props?.activeClassName === 'data-upload' ? 'active' : ''} to="/data-upload">Data Upload</Link>
                                    </li>
                                    <li>
                                        <Link className={props?.activeClassName === 'data-migration' ? 'active' : ''} to="/data-migration">Data Migration</Link>
                                    </li>
                                </ul>
                            </li>}
                        </ul>
                    </div>
                </div>
            </Scrollbars>
        </div>
    )
}
export default Sidebar
